/* Home.css */

.home {
  background-color: #e6f7ff;
  height: 100vh;
  font-family: 'Arial', sans-serif;
}


.header {
  display: flex;
  justify-content: center; /* Centra horizontalmente */
  align-items: center; /* Centra verticalmente */
  padding: 10px 20px;
  background-color: #b3e0ff;
  color: #0077b3;
}

.menu-icon {
  margin-left: auto; /* Empuja el icono del menú hacia el extremo derecho */
}


.navigation {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  padding: 20px;
  gap: 10px;
}

button {
  background-color: #00b0f0;
  border: none;
  border-radius: 20px;
  color: white;
  padding: 15px;
  font-size: 18px;
  text-align: left;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, box-shadow 0.3s;
  cursor: pointer;
  outline: none;
}

button:hover {
  background-color: #0077b3;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
}

button:before {
  content: '▶ ';
  color: #e6f7ff;
}

h1 {
  text-align: center;
  color: #08081a;
  margin-bottom: 10px;
  font-weight: 700;
}
