/* Establece un gradiente de fondo para toda la aplicación */
.App {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(135deg, #6e8efb, #a777e3);
}

/* Estiliza el formulario de login */
form {
  padding: 40px;
  background: white;
  border-radius: 20px;
  box-shadow: 0 4px 15px rgba(0,0,0,0.2);
  width: 100%;
  max-width: 270px;
}

/* Añade estilos a los inputs */
input {
  width: 100%;
  padding: 15px;
  margin: 10px 0;
  border-radius: 10px;
  border: 1px solid #ccc;
  box-shadow: inset 0 1px 3px rgba(0,0,0,0.1);
  transition: border-color 0.3s;
}

input:focus {
  border-color: #a777e3;
  outline: none;
}

/* Estiliza el botón de submit */
button {
  width: 100%;
  padding: 15px;
  border: none;
  border-radius: 10px;
  background-color: #6e8efb;
  color: white;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #5d7ce2;
}

/* Centra el título y añade algo de estilo */
h2 {
  text-align: center;
  color: #2e2cac;
  margin-bottom: 20px;
  font-weight: 1000;
}

h3 {
  text-align: center;
}

h4 {
  text-align: center;
  font-style: italic;
  cursor: pointer;
}

